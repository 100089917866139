//@ts-ignore
import {bind, BindingScope, config, inject} from '@loopback/core';
import {DataSet} from 'vis-data';
import AsyncStorage from '@react-native-community/async-storage';

export const AUTH_TOKEN = '@vlinder:auth-token';
export const ACTOR = '@vlinder:actor';
export const USER_PROFILE = '@vlinder:user-profile';
export const GRAPHQL_URL = '@vlinder: graphql-url';

@bind({scope: BindingScope.SINGLETON})
export class AuthService {
  private static instance: AuthService;
  authTokenDS = new DataSet({
    fieldId: 'id',
  });
  userProfileDS = new DataSet({
    fieldId: 'id',
  });
  actorDS = new DataSet({
    fieldId: 'id',
  });
  graphqlURLDS = new DataSet({
    fieldId: 'id'
  });
  private constructor() {}

  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }

    return AuthService.instance;
  }

  async init() {
    await this.getAuthToken();
    await this.getActor();
    await this.getUserProfile();
    await this.getGraphqlURL()
  }

  async setAuthToken(token: string) {
    try {
      await AsyncStorage.setItem(AUTH_TOKEN, token);
      const _authTokenDS = {
        id: AUTH_TOKEN,
        value: token,
      };
      this.authTokenDS.update(_authTokenDS);
    } catch (e) {
      // saving error
    }
  }

  async setActor(actor: string) {
    try {
      await AsyncStorage.setItem(ACTOR, actor);
      const _actorDS = {
        id: ACTOR,
        value: actor,
      };
      this.actorDS.update(_actorDS);
    } catch (e) {
      // saving error
    }
  }

  async setUserProfile(userProfile: any) {
    console.log('set user profile services', userProfile)
    try {
      let _userProfile = userProfile;
      if (userProfile && typeof userProfile !== 'string') {
        _userProfile = JSON.stringify(userProfile);
      }
      await AsyncStorage.setItem(USER_PROFILE, _userProfile);
      const _userProfileDS = {
        id: USER_PROFILE,
        value: userProfile,
      };
      this.userProfileDS.update(_userProfileDS);
    } catch (e) {
      // saving error
    }
  }

  async getActor() {
    try {
      const value = await AsyncStorage.getItem(ACTOR);
      console.log('inside get actorservice', value);
      if (value !== null) {
        // value previously stored
        const _actorDS = {
          id: ACTOR,
          value: value,
        };
        this.actorDS.update(_actorDS);
        return value;
      }
      else{
        const _value = this.actorDS.get();
        console.log('actor inside else', _value);
        let temp = _value.find(item=>{
          return item?.value;
        });
        return temp?.value;
      }
    } catch (e) {
      // error reading value
    }
  }
  async getUserProfile() {
    try {
      const value = await AsyncStorage.getItem(USER_PROFILE);
      console.log('get user profile', value);
      if (value !== null) {
        // value previously stored
        const _userProfileDS = {
          id: USER_PROFILE,
          value: JSON.parse(value),
        };
        this.userProfileDS.update(_userProfileDS);
        return JSON.parse(value);
      }
    } catch (e) {
      // error reading value
    }
  }

  async getAuthToken() {
    try {
      const value = await AsyncStorage.getItem(AUTH_TOKEN);
      if (value !== null) {
        // value previously stored
        const _authTokenDS = {
          id: AUTH_TOKEN,
          value: value,
        };
        this.authTokenDS.update(_authTokenDS);
        return value;
      }
    } catch (e) {
      // error reading value
    }
  }

  async setGraphqlURL(url: string) {
    console.log(`in setGraphqlURL`, url)
    try {
      await AsyncStorage.setItem(GRAPHQL_URL, url);
      const _graphqlDS = {
        id: GRAPHQL_URL,
        value: url
      };
      this.graphqlURLDS.update(_graphqlDS);
    } catch(e) {
      // saving error
    }
  }

  async getGraphqlURL() {
    try {
      const value = await AsyncStorage.getItem(GRAPHQL_URL);
      if(value !== null) {
        const _graphqlDS = {
          id: GRAPHQL_URL,
          value: value
        };
        this.graphqlURLDS.update(_graphqlDS);
        return value;
      }
    } catch (e) {
      // error reading value
    }
  }

  async removeAuthToken() {
    try {
      this.authTokenDS.clear();
      await AsyncStorage.removeItem(AUTH_TOKEN);
    } catch (e) {
      // remove error
    }
  }

  async removeActor() {
    try {
      this.actorDS.clear();
      await AsyncStorage.removeItem(ACTOR);
    } catch (e) {
      // remove error
    }
  }

  async removeGraphqlURL() {
    try {
      this.graphqlURLDS.clear();
      await AsyncStorage.removeItem(GRAPHQL_URL);
    } catch (e) {
      // remove error
    }
  }

  async removeUserProfile() {
    try {
      this.userProfileDS.clear();
      await AsyncStorage.removeItem(USER_PROFILE);
    } catch (e) {
      // remove error
    }
  }

  async signIn({
    newToken,
    rememberMe = false,
    actorName,
    userProfile,
  }: {
    newToken: string;
    rememberMe?: boolean;
    actorName?: string;
    userProfile?: any;
  }) {
    // console.log('inside user sign in', userProfile);
    if (rememberMe) {
      if (newToken) {
        await this.setAuthToken(newToken);
      }
      if (actorName) {
        await this.setActor(actorName);
      }
      if (userProfile) {
        console.log('inside user sign in set profile 1', userProfile);
        await this.setUserProfile(userProfile);
      }
    } else {
      if (newToken) {
        const _authTokenDS = {
          id: AUTH_TOKEN,
          value: newToken,
        };
        this.authTokenDS.update(_authTokenDS);
      }
      if (actorName) {
        const _actorDS = {
          id: ACTOR,
          value: actorName,
        };
        this.actorDS.update(_actorDS);
      }
      if (userProfile) {
        console.log('inside user sign in set profile', userProfile);
        // const _userProfileDS = {
        //   id: USER_PROFILE,
        //   value: userProfile,
        // };
        // this.userProfileDS.update(_userProfileDS);
        await this.setUserProfile(userProfile);
      }
    }
  }

  async signOut() {
    await this.removeAuthToken();
    await this.removeActor();
    await this.removeUserProfile();
    await this.removeGraphqlURL();
  }

  isAuthenticated() {
    const token = this.authTokenDS.get(AUTH_TOKEN);
    // console.log('auth token', token);
    // return true;
    if (token) {
      return true;
    }
    return false;
  }
}
