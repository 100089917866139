import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import PropagateLoader from "react-spinners/PropagateLoader";
import config from './default-blueprint.json'
const BG_COLOR = '#1a4677';
interface IProps {
  backgroundColor?: string;
  loadingText?: string;
}

const BP = config.loader
export const LoaderV = (props: IProps) => {
  return (
    <View>

      <div id="loaderInverseWrapper" style={{ height: '100vh', background: "linear-gradient(-179deg, rgb(255, 255, 255), rgb(255, 255, 255))" }}>

        <View style={{ marginBottom: 10 }}>
          {/* <ImageV
            source={"https://i.ibb.co/3rgTgJL/loader.png"}
            imageStyle={StyleSheet.flatten([styles.imageStyle])}
            containerStyle={StyleSheet.flatten([styles.containerStyle])}
        /> */}
          <View style={{ alignItems: 'center', marginTop: '45vh' }}>
            <PropagateLoader color={"darkgrey"} loading={true} size={15} />
          </View>
        </View>
        <View style={{ marginTop: 40 }}>
          <Text style={{ color: "darkgrey", fontSize: 20, fontWeight: "400", textAlign: 'center' }}>{props.loadingText}</Text>
        </View>

        {/* <Loader inverse  content="Signing in to klefki..." /> */}

        {/* <ImageV
            source={"https://i.ibb.co/Wv3c8P9/klefki-icon-logo.png"}
            imageStyle={StyleSheet.flatten([styles.logoStyle])}
            containerStyle={StyleSheet.flatten([styles.logoContainerStyle])}
        />
    */}

      </div>
    </View>
  );
};


// backgroundColor: "#537895";
// backgroundImage: "linear-gradient(315deg, #537895 0%, #09203f 74%)"

LoaderV.defaultProps = {
  loadingText: BP.text,
}

const styles = StyleSheet.create({
  imageStyle: {
    width: '100%',
    height: '40vh',
    resizeMode: 'contain'
  },
  containerStyle: {
    marginTop: '50%',
  },
  logoContainerStyle: {
    marginTop: "20%"
  },
  logoStyle: {
    width: '100%',
    height: '8vh',
    resizeMode: 'contain'
  }
});